<template>
  <Lock :wiki="wiki">
    <article class="uk-height-viewport uk-flex uk-flex-column uk-flex-middle">
      <SiteHeader class="uk-margin-top"></SiteHeader>
      <section class="uk-width-xlarge uk-padding">
        <ul class="uk-breadcrumb uk-flex-left">
          <li><router-link to="/home">home</router-link></li>
          <li><router-link :to="{ name: 'projects', params: { wiki }}">{{ wiki }}</router-link></li>
          <li><router-link :to="{ name: 'project', params: { path: path }}">{{ path }}</router-link></li>
          <li><span>{{ label }}</span></li>
        </ul>
        <form class="uk-form-stacked uk-clearfix" v-on:submit.prevent="validate()" v-if="service">
          <label class="uk-flex uk-flex-column uk-margin-bottom">
            <span>token<span class="uk-text-muted"> {{ error.token }}</span></span>
            <div class="uk-form-controls uk-inline">
                <i class="uk-form-icon" uk-icon="icon: unlock"></i>
                <input
                  class="uk-input"
                  type="text"
                  v-model="token"
                  placeholder="enter your API token"
                  autofocus
                  required
                  :disabled="!service.hasValidateToken"
                  spellcheck="false"
                  autocorrect="off"
                  autocapitalize="off"
                  autocomplete="off">
            </div>
            <p class="uk-margin-large-left uk-margin-small-top uk-text-right uk-text-meta
                  uk-margin-remove-bottom uk-animation-fade" v-if="help">
              A valid API token gives you access to this project in a specific format
              (client SDK or backend project). This token can be used by your build
              tooling to keep all the API contracts up-to-date. 
            </p>
          </label>

          <SubmitFooter v-if="token !== validated" label="validate" :error="error.msg" :active="active"></SubmitFooter>
        </form>
        <section v-if="validated && token === validated">
          <form class="uk-form-stacked" :action="action">
            <input
              class="uk-input"
              type="hidden"
              name="token"
              :value="validated"
              required>
            <input
              class="uk-input"
              type="hidden"
              name="branch"
              :value="label"
              required>
            <input
              class="uk-input"
              type="hidden"
              name="version"
              :value="label === 'final' ? version : ''"
              required>
            <p class="uk-margin-remove-top uk-text-meta">
              {{ description }}
            </p>
            <label class="uk-flex uk-flex-column uk-margin-bottom" v-if="format === 'client' || format === 'project'">
              <span>url</span>
              <div class="uk-form-controls uk-inline">
                  <i class="uk-form-icon" uk-icon="icon: world"></i>
                  <input
                    class="uk-input"
                    type="url"
                    name="url"
                    placeholder="enter the url of your platform">
              </div>
              <p class="uk-margin-large-left uk-margin-small-top uk-text-right uk-text-meta
                    uk-margin-remove-bottom uk-animation-fade" v-if="help && format === 'client'">
                Providing a URL means the generated project will include a complete admin interface.
              </p>
              <p class="uk-margin-large-left uk-margin-small-top uk-text-right uk-text-meta
                    uk-margin-remove-bottom uk-animation-fade" v-else-if="help">
                Providing a URL means the project will be generated as an OpenAPI specification
                with links to your server of choice, meaning your download will be able to immediately
                integrate with tools like Postman.
              </p>
            </label>
            <SubmitFooter label="generate"></SubmitFooter>
          </form>
        </section>
      </section>
      <div class="uk-flex-1"></div>
      <SiteFooter class="uk-flex-none uk-flex-bottom"></SiteFooter>
    </article>
  </Lock>
</template>

<script>
import { mapState } from 'vuex';

import Lock from '@/components/Lock.vue';
import SiteHeader from '@/components/SiteHeader.vue';
import SiteFooter from '@/components/SiteFooter.vue';
import SubmitFooter from '@/components/SubmitFooter.vue';

export default {
  name: 'Code',
  components: {
    Lock,
    SiteHeader,
    SiteFooter,
    SubmitFooter,
  },
  props: {
      wiki: String,
      path: String,
      label: String,
  },
  data() {
    return {
      token: '',
      validated: '',
      action: `/acorn/consumer/module/${this.wiki}/${this.path}/generate`,
      active: false,
      error: {
        msg: null,
        token: null,
      },
      description: null,
    };
  },
  methods: {
    async validate() {
      this.active = true;
      this.error = {
        msg: null,
        token: null,
      };
      try {
          let metadata = await this.service.asValidateTokenUri().postAsync(
            this.wiki,
            this.path,
            this.token,
            this.label,
            this.version);
          this.validated = this.token;
          this.description = metadata.description;
          this.format = metadata.format;
      } catch (err) {
          this.error.msg = err.message ?? 'An unexpected error occurred';
          if (!err.status) {
              console.error(err);
          } else {
              var tree = this.$error(err.status, err.error);
              this.error.token = tree.claim('/token');
              if (tree.assert_claimed()) {
                  this.error = 'the form cannot display an important error';
              }
          }
      } finally {
          this.active = false;
      }
    },
  },
  computed: {
    ...mapState('api', ['help', 'service']),
  },
}
</script>